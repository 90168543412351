<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <detail-sanitaire
      :animal="animal"
      :create-by="createBy"
      :create-at="createAt"
    >
      <template slot="header">
        <h4>{{ maladie }}</h4>
      </template>
      <template slot="content"> 
        <div class="row info">
          <div class="col-12">
            <h6>Maladie</h6>
            <p>{{ maladie }}</p>
          </div>
        </div>
        <div class="row info">
          <div class="col-12">
            <h6>Médicaments</h6>
            <ul>
              <li
                v-for="(m, i) in medicaments"
                :key="i"
              >
                {{ getMedicament(m.medoc) }} [{{ m.protocole }}]
              </li>
            </ul>
          </div>
        </div>
        <div class="row info">
          <div class="col-12">
            <h6>Information supplémentaire</h6>
            <p>{{ infoSup }}</p>
          </div>
        </div>
        <!-- {{ traitement }} -->
      </template>
    </detail-sanitaire>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Navbar from '../../../components/navbar.vue';
import DetailSanitaire from '../../../components/sanitaire/detailSanitaire.vue';
export default {
    components: { Navbar, DetailSanitaire},
    data(){
        return {
            navbarItems: [
              {
                  libelle: 'Sanitaire'
              },
              {
                libelle: 'Traitements',
                route: 'traitement'
              },
              {libelle: 'Détail'}
            ],
            pageIcon: 'la-id-card',
            pageTitle: 'Détail traitement',
            pageDescription: 'Détail du traitement apporté sur un animal',
            traitement: null
        }
    },
    watch: {
        traitements: {
            handler(){
                this.load()
            },
            deep: true
        }
    },
    mounted(){
        this.load()
    },  
    methods: {
        load(){
            if(this.$route.params.uid !== null){
                this.traitement =  this.traitements.find(item => item.uid === this.$route.params.uid)
            }else{
                this.traitement =  null
            }
        },
        getMedicament(uid){
            console.log(uid)
            let m = this.allMedicments.find(item => item.uid === uid)
            console.log(m)
            if(m) return m.libelle
            return '-'
        }
    },
    computed: {
        ...mapGetters({
            traitements: 'sanitaire/traitements',
            maladies: 'sanitaire/maladies',
            allMedicments: 'sanitaire/medicaments',
        }),
        hasTraitement() { return this.traitement !== null && this.traitement !== undefined},
        animal(){
            if(this.hasTraitement) return this.traitement.animal
            return null
        },
        createBy(){
            return (this.hasTraitement)? this.traitement.createBy:null
        },
        createAt(){
            return (this.hasTraitement)? this.traitement.createdAt:null
        },
        maladie() {
            if(this.hasTraitement){
                let m = this.maladies.find(item => item.uid === this.traitement.maladie)
                if(m) return m.libelle
            }
            return '-'
        },
        medicaments(){
            return (this.hasTraitement) ?  JSON.parse(this.traitement.medicaments) : []
        },
        infoSup(){
            return (this.hasTraitement) ?  this.traitement.info : '-'
        }
        
    }
    
}
</script>

<style>

</style>